<template>
  <div class="tabs">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="字典配置" name="first">
        <Dict v-if="activeName=='first'"></Dict>
      </el-tab-pane>
      <!-- <el-tab-pane label="安检计划配置" name="second">
        <Cycle v-if="activeName=='second'"></Cycle>
      </el-tab-pane>-->
      <el-tab-pane label="次数频率配置" name="third">
        <Freq v-if="activeName=='third'"></Freq>
      </el-tab-pane>
      <!--<el-tab-pane label="非居民采集配置" name="fourth">
        <CollecIndex v-if="activeName=='fourth'"></CollecIndex>
      </el-tab-pane>
      <el-tab-pane label="居民安检配置" name="five">
        <Security v-if="activeName=='five'"></Security>
      </el-tab-pane> -->
      <!-- <el-tab-pane label="居民安检周期" name="six">
        <civilConfig v-if="activeName=='six'"></civilConfig>
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>
<script>
import Dict from './dict.vue'
import Cycle from './cycle.vue'
import Freq from './freq.vue'
import CollecIndex from './collecIndex.vue'
import Security from './security.vue'
import civilConfig from './civilConfig.vue'
export default {
  name: 'Index',
  components: { Dict , Cycle , Freq , CollecIndex ,Security, civilConfig },
  data() {
    return {
      activeName: 'first',
      options:[],
      value:''
    }
  },
  mounted() {
    if(this.$route.query.first == 1) {
      this.activeName = 'fourth'
    }
    if(this.$route.query.first == 2) {
      this.activeName = 'five'
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  background: #ffffff;
  width: 100%;
  border-radius: 6px;
  padding: 10px;
}
.tabs{
  background-color: #fff;
  padding:0 20px 10px 20px;
  margin: 10px;
}
</style>
