<!-- 安检计划配置 -->
<template>
  <div style="background: #ffffff; width: 100%; border-radius: 6px">
    <!--数据表格-->
    <div
      style="margin-bottom: 10px; display: flex; justify-content: space-between"
    >
      <el-button
        type="text" 
        style="border: 1px solid #409EFF;color: #409EFF;width: 80px;"
        size="mini"
        @click="AddDataFun"
        v-if="$anthButtons.getButtonAuth('add')"
        >添加</el-button
      >
      <div>
        同一计划下用户任务是否可重复
        <el-switch v-model="switchValue" @change="changeRepeat"> </el-switch>
      </div>
    </div>
    <el-table
      ref="tableData"
      :data="tableData"
      style="width: 100%"
      border
      height="calc(100vh - 400px)"
      :stripe="true"
      @selection-change="handleSelectionChange"
      :header-cell-style="{
        color: '#333',
        fontFamily: 'MicrosoftYaHeiUI',
        fontSize: '14px',
        fontWeight: 900,
        background: '#f8f8f9',
        textAlign: 'left',
      }"
    >
      <el-table-column
        type="index"
        :index="indexMethod"
        label="序号"
        align="center"
        width="60"
      >
      </el-table-column>
      <el-table-column
        prop="periodName"
        label="安检计划名称"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="sort" label="排序" width="80"> </el-table-column>
      <el-table-column
        prop="periodCreateTime"
        label="添加时间"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="operation" label="操作" align="left" width="180">
        <template slot-scope="{ row }">
          <el-button
            v-if="$anthButtons.getButtonAuth('modify')"
            type="primary"
            size="small"
            style="margin-right: 10px"
            @click="AddDataFun(row)"
            >编辑</el-button
          >
          <el-button
            v-if="$anthButtons.getButtonAuth('del')"
            type="danger"
            size="small"
            style="margin-right: 10px"
            @click="delFun(row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="total, prev, pager, next,sizes, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="search.current"
      :page-sizes="[10, 30, 50, 100]"
      :page-size="search.size"
    >
    </el-pagination>
    <el-dialog
      :visible.sync="dialogVisible"
      width="576px"
      @closed="addDialogClosed"
      data-backdrop="static"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="dialog_form">
        <el-form label-width="80px" :model="form" ref="form" :rules="rules">
          <el-form-item
            label="安检计划名称"
            label-width="150px"
            class="searchItem"
            prop="periodName"
          >
            <el-input
              v-model="form.periodName"
              placeholder="请输入安检计划名称"
              clearable
              maxlength="30"
              show-word-limit
              name="limitLength"
              style="width: 80%; height: 40px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="排序"
            label-width="150px"
            class="searchItem"
            prop="sort"
          >
            <el-input
              v-model.number="form.sort"
              placeholder="请输入数字0-9999，数字越小，排序越靠前"
              clearable
              type="number"
              maxlength="4"
              @input="e=>form.sort = e > 9999 ? 9999 : e < 0 ? 0 : form.sort"
              style="width: 80%; height: 40px"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="diaBtn" @click="addDialogClosed">取 消</el-button>
        <el-button type="primary" @click="addUpdate">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  periodPageList,
  apPeriodInsertOrUpdate,
  selectRepeat,
  repeatSet,
  userTypePeriodDropDown,
  apPeriodDelete,
} from "../../RequestPort/dict/cycle";
export default {
  name: "Cycle",
  data() {
    return {
      search: {
        current: 1,
        size: 100,
      }, //搜索
      total: 10,
      tableData: [], //列表对象
      dialogVisible: false,
      form: {
        periodName: '',
        sort: 0,
      }, //新增数据表单
      rules: {
        periodName: {
          required: true,
          message: "请填写安检计划名称",
          trigger: "blur",
        },
        sort: { required: true, message: "请填写排序", trigger: "blur" },
      },
      userList: [],
      indexMethod: 1,
      options: [],
      value: "",
      switchValue: false,
    };
  },
  mounted() {
    this.getList();
    this.getRepeated();
    this.getUserTypes();
  },
  methods: {
    // 获取是否可重复
    getRepeated() {
        selectRepeat().then(res => {
            this.switchValue =  res.data === 1 ? true : false
        })
    },
    // 改变可重复switch
    changeRepeat() {
        repeatSet().then(res => {
            if (res.code != 200) {
                this.switchValue = !this.switchValue
            }
        })
    },
    //查列表
    getList() {
      periodPageList(this.search).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    //获取用户分类下拉
    getUserTypes() {
      userTypePeriodDropDown().then((res) => {
        this.userList = res.data;
      });
    },
    //打开新增/编辑弹框
    AddDataFun(row) {
      this.dialogVisible = !this.dialogVisible;
      this.getUserTypes();
      // console.log(row)
      if (row.id) {
        this.form = JSON.parse(JSON.stringify(row));
        // this.form.userType = row.userTypeName
      } else {
        this.form.sort = 0
      }
      this.$nextTick(function () {
        this.$refs["form"].clearValidate();
        console.log(this.form);
      });
    },
    //提交新增/编辑
    addUpdate(row) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.id) {
            let p = {
              id: this.form.id,
              periodName: this.form.periodName,
              sort: this.form.sort
            };
            apPeriodInsertOrUpdate(p).then((res) => {
              this.dialogVisible = !this.dialogVisible;
              if (res.code == 200) {
                this.$message.success("编辑成功");
              }
              this.getList();
            });
          } else {
            let p = {
              ...this.form,
            };
            apPeriodInsertOrUpdate(p).then((res) => {
              this.dialogVisible = !this.dialogVisible;
              if (res.code == 200) {
                this.$message.success("添加成功");
              }
              this.getList();
            });
          }
        } else {
          return false;
        }
      });
    },
    //关闭新增//编辑弹框
    addDialogClosed() {
      this.dialogVisible = false;
      this.form = {
        checkPeriod: 1,
        takeEffect: 1,
      };
      this.$refs.form.clearValidate();
    },
    //删除
    delFun(id) {
      let msg = "";
      if (typeof id == "object") {
        id = id.toString();
        msg = "确定删除选择的数据吗";
      } else {
        // msg = "删除后，该用户分类不再执行计划任务，是否确认删除";
         msg = "是否确认删除";
      }
      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      }).then(() => {
        let params = {
          id: id,
        };
        apPeriodDelete(params).then((res) => {
          if (res.code == 200) {
            this.$message.success("删除成功");
          }
          this.getList();
        });
      });
    },
    handleSelectionChange(val) {
      console.log(val);
    },
    handleSizeChange(val) {
      this.search.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.search.current = val;
      this.getList();
    },
  },
};
</script>

<style scoped>
.table {
  border: 1px solid #ecedf1;
  border-radius: 5px;
  margin-right: 1.5%;
}
.all {
  background-color: white;
}
.content {
  background: #ffffff;
  width: 100%;
  border-radius: 6px;
  padding: 10px;
}
.diaBtn {
  margin-right: 2%;
}
.diaInput {
  width: 70%;
}
.lab {
  margin-left: 2%;
}
/* .dialog_form {
  margin-left: 10%;
} */
.SearchBtn2 {
  margin-left: 1%;
}
</style>
