<!-- 安检周期配置 -->
<template>
  <div>
    <el-radio-group v-model="activeName" size="medium" style="margin-bottom:20px">
      <!-- <el-radio-button label="first">非民安检周期</el-radio-button> -->
      <!-- <el-radio-button label="second">居民安检计划</el-radio-button> -->
    </el-radio-group>
    <div v-if="activeName=='first'" style="background: #ffffff;
      width: 100%;
      border-radius: 6px;">
          <!--数据表格-->
          <div style="margin-bottom: 10px" >
              <el-button type="text" style="border: 1px solid #409EFF;color: #409EFF;width: 80px;" size="mini" @click="AddDataFun" v-if="$anthButtons.getButtonAuth('add')">添加</el-button>
          </div>
          <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%;"
            border
            height="calc(100vh - 345px)"
            :stripe="true"
            @selection-change="handleSelectionChange"
            :header-cell-style="{color:'#333',fontFamily:'MicrosoftYaHeiUI',fontSize:'14px',fontWeight:900,background:'#f8f8f9',textAlign:'left',}">
            <el-table-column
            type="index"
            :index="indexMethod"
            label="序号"
            align="center"
            width="60"
            >
            </el-table-column>
            <el-table-column prop="userTypeName" label="用户分类"  show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="checkPeriod" label="安检周期"  show-overflow-tooltip>
              <template slot-scope="{ row }">
                <!-- <el-radio-group v-model="row.checkPeriod" @change='ChangeUpdate(row)'>
                  <el-radio :label="1">一周</el-radio>
                  <el-radio :label="2">两周</el-radio>
                  <el-radio :label="3">三周</el-radio>
                  <el-radio :label="4">一个月</el-radio>
                </el-radio-group> -->
                <div v-if="row.checkPeriod == 1">一周</div>
                <div v-if="row.checkPeriod == 2">两周</div>
                <div v-if="row.checkPeriod == 3">三周</div>
                <div v-if="row.checkPeriod == 4">一个月</div>
              </template>
            </el-table-column>
            <el-table-column prop="takeEffect" label="安检周期更改生效时间"  show-overflow-tooltip>
              <template slot-scope="{ row }">
                {{row.takeEffect == 1 ? '立即生效/周期任务结束后生效' : '新的周期生效' }}
                <!-- <el-radio-group v-model="row.takeEffect" @change='ChangeUpdate(row)'>
                <el-radio :label="1">立即生效/周期任务结束后生效</el-radio>
                <el-radio :label="2">新的周期生效</el-radio>
              </el-radio-group> -->
              </template>
            </el-table-column>
            <el-table-column prop="operation" label="操作" align="left" width="250">
              <template slot-scope="{ row }">
                <el-button
                    v-if="$anthButtons.getButtonAuth('modify')"
                  type="primary"
                  size="small"
                  style="margin-right:10px"
                  @click="AddDataFun(row)"
                  >编辑</el-button
                >
                <el-button
                    v-if="$anthButtons.getButtonAuth('del')"
                  type="danger"
                  size="small"
                  style="margin-right:10px"
                  @click="delFun(row.id)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
      <el-dialog
        :visible.sync="dialogVisible"
        width="576px"
        @closed="addDialogClosed"
        data-backdrop="static"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <div class="dialog_form">
          <el-form label-width="80px" :model="form" ref="form" :rules="rules">
            <el-form-item label="用户分类" prop="userType" v-if="!form.id">
              <el-select v-model="form.userType" placeholder="请选择" clearable filterable style="width:100%">
                <el-option
                  v-for="item in userList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="安检周期" prop="checkPeriod">
              <el-radio-group v-model="form.checkPeriod">
                <el-radio :label="1">一周</el-radio>
                <el-radio :label="2">两周</el-radio>
                <el-radio :label="3">三周</el-radio>
                <el-radio :label="4">一个月</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="生效时间" prop="takeEffect">
              <el-radio-group v-model="form.takeEffect">
                <el-radio :label="1">立即生效/周期任务结束后生效</el-radio>
                <el-radio :label="2">新的周期生效</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button class="diaBtn" @click="addDialogClosed">取 消</el-button>
          <el-button type="primary" @click="addUpdate">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <civilConfig v-if="activeName=='second'"></civilConfig>
  </div>
</template>
<script>
import { periodList , periodInsertOrUpdate , userTypePeriodDropDown , periodDelete } from "../../RequestPort/dict/cycle";
import civilConfig from './civilConfig.vue';
export default {
  components: { civilConfig },
  name: 'Cycle',
  data() {
    return {
      activeName: 'second',
      search: {}, //搜索
      total: 10,
      tableData: [], //列表对象
      dialogVisible: false,
      form: {
        checkPeriod:1,
        takeEffect:1
      }, //新增数据表单
      rules: {
        userType:{ required: true, message: "请选择用户分类", trigger: "change" },
        checkPeriod:{ required: true, message: "请选择安检周期", trigger: "change" },
        takeEffect:{ required: true, message: "请选择安检周期", trigger: "change" },
      },
      userList:[],
      indexMethod:1,
      options:[],
      value:''
    }
  },
  mounted() {
    this.getList()
    this.getUserTypes()
  },
  methods: {
    //查列表
    getList() {
      periodList().then(res => {
        this.tableData = res.data
        console.log(this.tableData,'shinibu ')
      })
    },
    //获取用户分类下拉
    getUserTypes(){
      userTypePeriodDropDown().then(res=>{
        this.userList = res.data
      })
    },
    //打开新增/编辑弹框
    AddDataFun(row) {
      this.dialogVisible = !this.dialogVisible
      this.getUserTypes()
      // console.log(row)
      if(row.id){
        this.form = JSON.parse(JSON.stringify(row)) 
        // this.form.userType = row.userTypeName
      }
      this.$nextTick(function() {
        this.$refs['form'].clearValidate()
        console.log(this.form)
      })
    },
    // ChangeUpdate(row) {
    //         let p = {
    //           id: row.id, 
    //           checkPeriod: row.checkPeriod,
    //           takeEffect: row.takeEffect,
    //           userType: row.userType
    //         }
    //         periodInsertOrUpdate(p).then(res => {
    //           if (res.code == 200) {
    //             this.$message.success('编辑成功')
    //           }
    //           this.getList()
    //         })
    // },
    //提交新增/编辑
    addUpdate(row) {
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.form.id) {
            let p = {
              id: this.form.id, 
              checkPeriod: this.form.checkPeriod,
              takeEffect: this.form.takeEffect,
              userType: this.form.userType
            }
            periodInsertOrUpdate(p).then(res => {
              this.dialogVisible = !this.dialogVisible
              if (res.code == 200) {
                this.$message.success('编辑成功')
              }
              this.getList()
            })
          } else {
            let p = {
              ...this.form
            }
            periodInsertOrUpdate(p).then(res => {
              this.dialogVisible = !this.dialogVisible
              if (res.code == 200) {
                this.$message.success('配置完成')
              }
              this.getList()
            })
          }
        } else {
          return false
        }
      })
    },
    //关闭新增//编辑弹框
    addDialogClosed() {
      this.dialogVisible = false
      this.form ={
        checkPeriod:1,
        takeEffect:1
      }
      this.$refs.form.clearValidate()
    },
    //删除
    delFun(id) {
      let msg = ''
      if (typeof id == 'object') {
        id = id.toString()
        msg = '确定删除选择的数据吗'
      } else {
        msg = '删除后，该用户分类不再执行周期任务，是否确认删除'
      }
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
        type: 'warning'
      }).then(() => {
        let params = {
          id: id
        }
        periodDelete(params).then(res => {
          if (res.code == 200) {
            this.$message.success('删除成功')
          }
          this.getList()
        })
      })
    },
    handleSelectionChange(val) {
      console.log(val)
    },
    handleSizeChange(val) {
      this.search.size = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.search.current = val
      this.getList()
    }
  }
}
</script>

<style scoped>
.table {
  border: 1px solid #ecedf1;
  border-radius: 5px;
  margin-right: 1.5%;
}
.all {
  background-color: white;
}
.content {
  background: #ffffff;
  width: 100%;
  border-radius: 6px;
  padding: 10px;
}
.diaBtn {
  margin-right: 2%;
}
.diaInput {
  width: 70%;
}
.lab {
  margin-left: 2%;
}
.dialog_form {
  margin-left: 10%;
}
.SearchBtn2 {
  margin-left: 1%;
}
</style>
